import Navbar from "../Components/Navbar";
import Topbar from "../Components/Topbar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { sendPostRequest, useGetRequest } from "../Client";
import { React, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { dateFormatter } from "../config";
import { PlusIcon, MinusIcon } from "@heroicons/react/20/solid";
import ShelfRow from "../Components/Receving/ShelfRow";
import SkidRow from "../Components/Receving/SkidRow";
import ReceiverUpdate from "../Components/Receving/Update";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

function Receiving() {
  const initialDate = new Date().toISOString().split("T")[0];
  const initialDateArray = initialDate.split("-");

  const [shelf, setShelf] = useState(1);
  const [shelfs, setShelfs] = useState(1);
  const [shelfData, setShelfData] = useState([]);

  const [skids, setSkids] = useState(1);
  const [skidData, setSkidData] = useState([]);

  const [inputs, setInputs] = useState({
    date:
      initialDateArray[0] +
      "-" +
      initialDateArray[1] +
      "-" +
      initialDateArray[2],
  });
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [receivedList, setReceivedList] = useState([]);
  const [locations, setLocations] = useState([]);

  const [charge8, setCharge8] = useState(0);
  const [charge9, setCharge9] = useState(0);
  const [charge10, setCharge10] = useState(0);

  let shelfTotalQuantity = 0;

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  function addRemoveLocation(value) {
    // const locationValue = Number(value);
    const locationValue = value;
    if (locationValue) {
      if (locations.includes(value)) {
        const locationWithoutThisValue = locations.filter((x) => x != value);
        setLocations(locationWithoutThisValue);
      } else {
        setLocations([...locations, value]);
      }
    }
  }

  const receivingDelete = async (id) => {
    const res = await sendPostRequest("receiving/delete", {
      stockMovementID: id,
    });

    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requestBody = {
      inventoryID: inputs.inventoryID,
      customerID: inputs.customerID,
      skids: shelf === 1 ? 0 : locations.length,
      cartonsPerSkid: inputs.cartonsPerSkid,
      unitsPerCarton: inputs.unitsPerCarton,
      unitWeight: inputs.unitWeight,
      numberCartons: inputs.numberCartons,
      date: inputs.date,
      shelfData: JSON.stringify(shelfData),
      skidData: JSON.stringify(skidData),
      carrierName: inputs.carrierName,
      vendorName: inputs.vendorName,
      vendorAddress: inputs.vendorAddress,
      city: inputs.city,
      state: inputs.state,
      zip: inputs.zip,
      phone: inputs.phone,
      locations: JSON.stringify(locations),
      calculation:
        shelf === 1
          ? "Per item up to 100lbs ($" +
            charge9 +
            ") + Total weight over 100 lbs (" +
            getWeightCharge(
              Number(inputs.unitWeight) *
                getShelfTotalQuantity(shelfData, shelfs)
            ) +
            " lbs) x $" +
            charge10
          : "Per item up to 100lbs ($" +
            charge9 +
            ") + Total weight over 100 lbs (" +
            getWeightCharge(
              Number(inputs.unitWeight) * getSkidTotalQuantity(skidData, skids)
            ) +
            " lbs) x $" +
            charge10 +
            " + Number of skids x skid charge (" +
            charge8 +
            " x " +
            locations.length +
            ")",
      quantity:
        shelf === 0
          ? getSkidTotalQuantity(skidData, skids)
          : getShelfTotalQuantity(shelfData, shelfs),
      amount:
        shelf === 0
          ? getSkidProcessingCharge(
              locations.length,
              getSkidTotalQuantity(skidData, skids),
              inputs.unitWeight,
              charge10,
              charge9,
              charge8
            ) + Number(locations.length * charge8)
          : getShelfProcessingCharge(
              inputs.unitWeight,
              charge10,
              charge9,
              getShelfTotalQuantity(shelfData, shelfs)
            ),
    };

    setTimeout(() => {
      window.location.reload(false);
    }, 500);

    setOpen(false);

    const res = await sendPostRequest("receiving", requestBody);
  };

  const confirmReceiving = async () => {
    const res = await sendPostRequest("receiving/confirm", {});
    console.log("res", res);
    setReceivedList(res.list);
  };

  const { status: statusCustomers, data: dataCustomers } = useGetRequest(
    "customers",
    {}
  );

  const { status: statusInventory, data: dataInventory } = useGetRequest(
    "inventory/" + inputs.customerID,
    {}
  );

  const {
    status: statusCustomerProcessingCharges,
    data: dataCustomerProcessingCharges,
  } = useGetRequest("customer/processing/charges/" + inputs.customerID, {});

  const { status: statusReceived, data: dataReceived } = useGetRequest(
    "receiving/list",
    {}
  );

  const { status: statusLocations, data: dataLocations } = useGetRequest(
    "locations",
    {}
  );

  useEffect(() => {
    setReceivedList(dataReceived.list);
  }, [dataReceived]);

  useEffect(() => {
    setCharge8(dataCustomerProcessingCharges.charge8);
    setCharge9(dataCustomerProcessingCharges.charge9);
    setCharge10(dataCustomerProcessingCharges.charge10);
  }, [dataCustomerProcessingCharges]);

  function getWeightCharge(weight) {
    let total = Number(weight) - 100;

    if (total < 0) {
      return Number(0);
    } else {
      return Number(total);
    }
  }

  function getShelfTotalQuantity(shelfData, shelfs) {
    let total = 0;
    let i = 0;

    while (i < shelfs) {
      total +=
        Number(shelfData[i + "-numberCartons"]) *
        Number(shelfData[i + "-unitsPerCarton"]);
      i++;
    }

    return Number(total);
  }

  function getSkidTotalQuantity(skidData, skids) {
    let total = 0;
    let i = 0;

    while (i < skids) {
      total +=
        Number(skidData[i + "-skids"]) *
        Number(skidData[i + "-cartonsPerSkid"]) *
        Number(skidData[i + "-unitsPerCarton"]);
      i++;
    }

    return Number(total);
  }

  function getSkidProcessingCharge(
    skids,
    totalSkidQuantity,
    unitWeight,
    charge10,
    charge9,
    charge8
  ) {
    const weightCharge = getWeightCharge(
      Number(unitWeight) * Number(totalSkidQuantity)
    );

    let total =
      Number(weightCharge) * Number(charge10) +
      Number(charge9) +
      Number(locations.length) * Number(charge8);

    return total.toFixed(2);
  }

  function getShelfProcessingCharge(
    unitWeight,
    charge10,
    charge9,
    shelfTotalQuantity
  ) {
    let weightCharge = getWeightCharge(
      Number(inputs.unitWeight) * Number(shelfTotalQuantity)
    );

    let total = Number(weightCharge * charge10) + Number(charge9);

    return total.toFixed(2);
  }

  // edit

  const [stockMovementID, setStockMovementID] = useState();
  const [editOpen, setEditOpen] = useState(false);

  const queryClient = useQueryClient();

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const errorNotyf = () => {
    notyf.success({
      message: "Email already being used",
      background: "#FF0000",
    });
  };

  const updatedNotyf = () => {
    notyf.success("User updated");
  };


  // close edit

  if (
    statusCustomers == "fetched" &&
    statusReceived == "fetched" &&
    statusCustomerProcessingCharges == "fetched"
  ) {
    return (
      <>
        <div className="flex flex-row">
          <Navbar />

          <div className="flex flex-col w-4/5 bg-gray-100">
            <Topbar />

            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-60">
              <form onSubmit={handleSubmit}>
                <div className="space-y-12">
                  <div className="pb-5 mt-8">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto flex flex-row">
                        <h1 className="text-2xl font-semibold leading-6 text-gray-900">
                          Receiving
                        </h1>
                      </div>

                      <Link to="/locations">
                        <div className="mt-4 sm:ml-8 sm:mt-0 sm:flex-none">
                          <button
                            type="button"
                            className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          >
                            Locations
                          </button>
                        </div>
                      </Link>

                      {receivedList?.length > 0 ? (
                        <div className="mt-4 sm:ml-8 sm:mt-0 sm:flex-none">
                          <button
                            type="button"
                            onClick={() => setOpenConfirm(true)}
                            className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                          >
                            Confirm Shipments
                          </button>
                        </div>
                      ) : null}

                      <div className="mt-4 sm:ml-8 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => setOpen(true)}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Add Item
                        </button>
                      </div>

                      <Link to="/receiving-previous">
                        <div className="mt-4 sm:ml-8 sm:mt-0 sm:flex-none">
                          <button
                            type="button"
                            // onClick={() => setOpen(true)}
                            className="block rounded-md bg-slate-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                          >
                            Previous Shipments
                          </button>
                        </div>
                      </Link>
                    </div>
                    <div className="mt-8 flow-root mb-5">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="w-1/6 py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                                >
                                  Date
                                </th>

                                <th
                                  scope="col"
                                  className="w-1/6 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Customer
                                </th>

                                <th
                                  scope="col"
                                  className="w-1/4 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Inventory Item
                                </th>

                                <th
                                  scope="col"
                                  className="w-1/6 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Quantity
                                </th>

                                <th
                                  scope="col"
                                  className="w-1/6 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Skids
                                </th>

                                <th
                                  scope="col"
                                  className="w-1/6 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                ></th>
                              </tr>
                            </thead>

                            {receivedList?.length > 0 ? (
                              <tbody className="divide-y divide-gray-200">
                                {receivedList?.map((item, key) => (
                                  <tr>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                      {dateFormatter(item.receivingDate)}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {item.customerName}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {item.productCode} - {item.description}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {item.quantity}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {item.skids}
                                    </td>
                                    <td className="block text-right">
                                      <span className="mt-2 isolate inline-flex rounded-md shadow-sm">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setStockMovementID(
                                              item.stockMovementID
                                            );
                                            setEditOpen(true);
                                          }}
                                          className="relative inline-flex items-center rounded-l-md bg-amber-500 hover:bg-amber-400 focus-visible:outline-amber-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-10"
                                        >
                                          Edit
                                        </button>

                                        <button
                                          type="button"
                                          onClick={() =>
                                            receivingDelete(
                                              item.stockMovementID
                                            )
                                          }
                                          className="relative -ml-px inline-flex items-center rounded-l-md rounded-r-md bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 focus:z-10"
                                        >
                                          Delete
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <>
                                <tbody className="divide-y divide-gray-200">
                                  <tr>
                                    <td
                                      colSpan="5"
                                      className="w-full text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 col-span-6"
                                    >
                                      -- No shipments found --
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* content */}
          </div>
        </div>

        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <form onSubmit={handleSubmit}>
                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                          <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                  onClick={() => setOpen(false)}
                                ></button>
                              </div>
                            </div>

                            <table className="min-w-full divide-y divide-gray-300 mt-10">
                              <tbody className="divide-gray-200">
                                <tr>
                                  <td className="w-1/3">
                                    {" "}
                                    <label
                                      htmlFor="region"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                      Date Received
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                    <input
                                      type="date"
                                      name="date"
                                      onChange={handleChange}
                                      value={inputs.date || ""}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td className="w-1/3">
                                    {" "}
                                    <label
                                      htmlFor="carrierName"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                      Carrier Name
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                    <input
                                      type="text"
                                      name="carrierName"
                                      onChange={handleChange}
                                      value={inputs.carrierName || ""}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td className="w-1/3">
                                    {" "}
                                    <label
                                      htmlFor="vendorName"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                      Vendor Name
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                    <input
                                      type="text"
                                      name="vendorName"
                                      onChange={handleChange}
                                      value={inputs.vendorName || ""}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td className="w-1/3">
                                    {" "}
                                    <label
                                      htmlFor="vendorAddress"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                      Vendor Address
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                    <input
                                      type="text"
                                      name="vendorAddress"
                                      onChange={handleChange}
                                      value={inputs.vendorAddress || ""}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td className="w-1/3">
                                    {" "}
                                    <label
                                      htmlFor="city"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                      City
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                    <input
                                      type="text"
                                      name="city"
                                      onChange={handleChange}
                                      value={inputs.city || ""}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td className="w-1/3">
                                    {" "}
                                    <label
                                      htmlFor="state"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                      State
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                    <input
                                      type="text"
                                      name="state"
                                      onChange={handleChange}
                                      value={inputs.state || ""}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td className="w-1/3">
                                    {" "}
                                    <label
                                      htmlFor="phone"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                      Phone
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                    <input
                                      type="text"
                                      name="phone"
                                      onChange={handleChange}
                                      value={inputs.phone || ""}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td className="w-1/3">
                                    {" "}
                                    <label
                                      htmlFor="zip"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                      Zip
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-2/3">
                                    <input
                                      type="text"
                                      name="zip"
                                      onChange={handleChange}
                                      value={inputs.zip || ""}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    {" "}
                                    <label
                                      htmlFor="customerID"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                      Customer
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                    <select
                                      id="customerID"
                                      name="customerID"
                                      required="required"
                                      onChange={handleChange}
                                      value={inputs.customerID || ""}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                      <option></option>
                                      {dataCustomers.customers.map(
                                        (customer) => (
                                          <option value={customer.customerID}>
                                            {customer.customerName}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </td>
                                </tr>

                                {dataInventory.status !== "success" ? null : (
                                  <tr>
                                    <td>
                                      {" "}
                                      <label
                                        htmlFor="inventoryID"
                                        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                      >
                                        Inventory Item
                                      </label>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                      <select
                                        id="inventoryID"
                                        name="inventoryID"
                                        onChange={handleChange}
                                        required="required"
                                        value={inputs.inventoryID || ""}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                      >
                                        <option></option>
                                        {dataInventory.inventory?.map(
                                          (item) => (
                                            <option value={item.inventoryID}>
                                              {/* {item.category} --{" "} */}
                                              {item.productCode} --{" "}
                                              {item.description}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </td>
                                  </tr>
                                )}

                                <tr>
                                  <td>
                                    {" "}
                                    <label
                                      htmlFor="shelf"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 py-4"
                                    >
                                      Shelf
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                    <input
                                      type="checkbox"
                                      name="shelf"
                                      id="shelf"
                                      checked={shelf === 1 ? true : false}
                                      onChange={(e) => {
                                        setShelf(e.target.checked ? 1 : 0);
                                      }}
                                      className="block w-4 rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </td>
                                </tr>

                                {dataLocations.status !== "success" ? null : (
                                  <>
                                    <tr>
                                      <td>
                                        {" "}
                                        <label
                                          htmlFor="location"
                                          className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                        >
                                          Location
                                        </label>
                                      </td>

                                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                        <label className="relative">
                                          <input
                                            type="checkbox"
                                            className="hidden peer"
                                          />
                                          {"Locations (+/-)"}

                                          <div className="absolute bg-white border transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100 peer-checked:pointer-events-auto">
                                            <ul className="grid grid-cols-3 w-64">
                                              {dataLocations.locations.map(
                                                (option, i) => {
                                                  return (
                                                    <li
                                                      className="mr-15"
                                                      key={option}
                                                    >
                                                      <label className="flex whitespace-nowrap cursor-pointer px-1 py-1 transition-colors hover:bg-blue-100 [&:has(input:checked)]:bg-blue-200">
                                                        <input
                                                          type="checkbox"
                                                          name="locations"
                                                          value={
                                                            // option.locationID
                                                            option.name
                                                          }
                                                          onChange={() => {
                                                            addRemoveLocation(
                                                              // option.locationID
                                                              option.name
                                                            );
                                                          }}
                                                          className="cursor-pointer"
                                                        />
                                                        <span className="ml-1 text-xs font-medium">
                                                          {option.name}
                                                        </span>
                                                      </label>
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </label>
                                      </td>
                                    </tr>
                                  </>
                                )}

                                {shelf === 0 ? (
                                  <>
                                    {[...Array(skids)].map((page, i) => (
                                      <SkidRow
                                        id={i}
                                        skidData={skidData}
                                        setSkidData={setSkidData}
                                      />
                                    ))}

                                    <div className="mt-2">
                                      <button
                                        type="button"
                                        onClick={() => setSkids(skids + 1)}
                                        className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      >
                                        <PlusIcon
                                          className="h-3 w-3"
                                          aria-hidden="true"
                                        />
                                      </button>
                                      {skids > 1 ? (
                                        <button
                                          type="button"
                                          onClick={() =>
                                            setSkids(
                                              skids > 1 ? skids - 1 : skids - 0
                                            )
                                          }
                                          className="border-1 ml-1 rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                          <MinusIcon
                                            className="h-3 w-3"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      ) : null}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {[...Array(shelfs)].map((page, i) => (
                                      <ShelfRow
                                        id={i}
                                        shelfData={shelfData}
                                        setShelfData={setShelfData}
                                      />
                                    ))}

                                    <div className="mt-2">
                                      <button
                                        type="button"
                                        onClick={() => setShelfs(shelfs + 1)}
                                        className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      >
                                        <PlusIcon
                                          className="h-3 w-3"
                                          aria-hidden="true"
                                        />
                                      </button>
                                      {shelfs > 1 ? (
                                        <button
                                          type="button"
                                          onClick={() =>
                                            setShelfs(
                                              shelfs > 1
                                                ? shelfs - 1
                                                : shelfs - 0
                                            )
                                          }
                                          className="border-1 ml-1 rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                          <MinusIcon
                                            className="h-3 w-3"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      ) : null}
                                    </div>
                                  </>
                                )}

                                <tr>
                                  <td>
                                    {" "}
                                    <label
                                      htmlFor="unitWeight"
                                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                      Unit weight (lbs)
                                    </label>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                    <input
                                      type="number"
                                      name="unitWeight"
                                      onChange={handleChange}
                                      required="required"
                                      value={inputs.unitWeight || ""}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <table className="min-w-full">
                              <tbody>
                                {shelf === 0 && inputs.unitWeight ? (
                                  <>
                                    <tr>
                                      <td className="text-sm text-gray-500 w-1/3">
                                        Total quantity:
                                      </td>
                                      <td className="text-sm text-gray-500 w-2/3 px-3">
                                        {getSkidTotalQuantity(skidData, skids)}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="text-sm text-gray-500 w-1/3">
                                        Total weight:
                                      </td>
                                      <td className="text-sm text-gray-500 w-2/3 px-3">
                                        {getSkidTotalQuantity(skidData, skids) *
                                          inputs.unitWeight}{" "}
                                        lbs
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="text-sm text-gray-500 w-1/3">
                                        Total charge:
                                      </td>
                                      <td className="text-sm text-gray-500 w-2/3 px-3">
                                        {getSkidProcessingCharge(
                                          locations.length,
                                          getSkidTotalQuantity(skidData, skids),
                                          inputs.unitWeight,
                                          charge10,
                                          charge9,
                                          charge8
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                ) : null}

                                {shelf === 1 && inputs.unitWeight ? (
                                  <>
                                    <tr className="py-5">
                                      <td className="text-sm text-gray-500 w-1/3">
                                        Total quantity:
                                      </td>

                                      {shelf === 0 ? null : (
                                        <td className="text-sm text-gray-500 w-2/3 px-3">
                                          {getShelfTotalQuantity(
                                            shelfData,
                                            shelfs
                                          )}
                                        </td>
                                      )}
                                    </tr>

                                    <tr className="py-5">
                                      <td className="text-sm text-gray-500 w-1/3">
                                        Total weight:
                                      </td>

                                      {shelf === 0 ? null : (
                                        <td className="text-sm text-gray-500 w-2/3 px-3">
                                          {getShelfTotalQuantity(
                                            shelfData,
                                            shelfs
                                          ) * inputs.unitWeight}
                                        </td>
                                      )}
                                    </tr>

                                    <tr className="py-5">
                                      <td className="text-sm text-gray-500 w-1/3">
                                        Customer charge:
                                      </td>

                                      {shelf === 0 ? null : (
                                        <td className="text-sm text-gray-500 w-2/3 px-3">
                                          $
                                          {getShelfProcessingCharge(
                                            inputs.unitWeight,
                                            charge10,
                                            charge9,
                                            getShelfTotalQuantity(
                                              shelfData,
                                              shelfs
                                            )
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  </>
                                ) : null}
                              </tbody>
                            </table>

                            <div className="mt-5">
                              <button
                                type="submit"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openConfirm} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenConfirm}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Confirm receiving list
                        </Dialog.Title>
                        <div className="mt-4 text-sm font-semibold text-gray-500">
                          Are you sure you would like to confirm these
                          shipments?
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 flex flex-row justify-between">
                      <div>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                          onClick={() => setOpenConfirm(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                          onClick={() => {
                            setOpenConfirm(false);
                            confirmReceiving();
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {stockMovementID && (
          <ReceiverUpdate
            editOpen={editOpen}
            setEditOpen={setEditOpen}
            errorNotyf={errorNotyf}
            updatedNotyf={updatedNotyf}
            // customerID={customerID}
            queryClient={queryClient}
            stockMovementID={stockMovementID}
          />
        )}
      </>
    );
  }
}

export default Receiving;
