import { React } from "react";
import Navbar from "../Components/Navbar";
import Topbar from "../Components/Topbar";
import { useState } from "react";
import { Link } from "react-router-dom";
import { sendPostRequest, useGetRequest } from "../Client";
import ReactLoading from "react-loading";
import { HomeIcon } from "@heroicons/react/20/solid";
import { dateFormatter } from "../config";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function OrderSearch() {
  const [searchStatus, setSearchStatus] = useState("");
  const [searchOrderNumber, setSearchOrderNumber] = useState("");
  const [searchCustomer, setSearchCustomer] = useState("");
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");

  const [fetchedData, setFetchedData] = useState([]);

  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);

  const { status: statusDropdownStatuses, data: dataDropdownStatuses } =
    useGetRequest("statuses/list", {});

  const { status: statusCustomers, data: dataCustomers } = useGetRequest(
    "customers",
    {}
  );

  const fetchPosts = async () => {
    setLoading(true);

    const requestBody = {
      status: searchStatus,
      orderID: searchOrderNumber,
      customer: searchCustomer,
      fromDate: searchFromDate,
      toDate: searchToDate,
    };

    const res = await sendPostRequest("orders/search", requestBody);

    setFetchedData(res.orders);

    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearched(true);
    fetchPosts();
  };

  if (statusDropdownStatuses == "fetched" && statusCustomers == "fetched") {
    return (
      <>
        <div className="flex flex-row w-full">
          <Navbar />

          <div className="flex flex-col w-4/5 bg-gray-100 mb-100">
            <Topbar />

            {/* content */}
            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-60">
              <form onSubmit={handleSubmit}>
                <div className="border px-2 py-2 mt-5">
                  <div className="w-full flex flex-row mt-4 justify-center align-end">
                    <div className="w-1/5">
                      <div className="mb-5 mx-2">
                        <label
                          htmlFor="status"
                          className="block text-sm font-medium text-gray-900 mb-3"
                        >
                          Status
                        </label>
                        <div className="">
                          <select
                            id="status"
                            name="status"
                            onChange={(e) => setSearchStatus(e.target.value)}
                            defaultValue={""}
                            className="h-[80%] w-full block rounded-md border border-transparent py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            <option></option>
                            {dataDropdownStatuses.statusList.map((status) => (
                              <option value={status.orderStatusID}>
                                {status.statusName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="w-1/5">
                      {" "}
                      <div className="mb-5 mx-2">
                        <label
                          htmlFor="orderNumber"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Order Number
                        </label>
                        <div className="mt-2">
                          <input
                            type="orderNumber"
                            name="orderNumber"
                            id="orderNumber"
                            onChange={(e) =>
                              setSearchOrderNumber(e.target.value)
                            }
                            className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-1/5">
                      <div className="mb-5 mx-2">
                        <label
                          htmlFor="customer"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Customer
                        </label>
                        <div className="mt-2">
                          <select
                            id="customer"
                            name="customer"
                            onChange={(e) => setSearchCustomer(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                          >
                            <option></option>
                            {dataCustomers.customers.map((customer) => (
                              <option value={customer.customerID}>
                                {customer.customerName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/5">
                      <div className="mb-5 mx-2">
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="fromDate"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            From Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              name="fromDate"
                              id="fromDate"
                              onChange={(e) =>
                                setSearchFromDate(e.target.value)
                              }
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/5">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="toDate"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          To Date
                        </label>
                        <div className="mt-2">
                          <input
                            type="date"
                            name="toDate"
                            id="toDate"
                            onChange={(e) => setSearchToDate(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full grid justify-items-end">
                    <button
                      type="submit"
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>

              {loading ? (
                <div className="inline-block min-w-full py-2 flex justify-center sm:px-6 lg:px-8">
                  <ReactLoading type={"spin"} color="#5046e5" />
                </div>
              ) : (
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="relative py-6">
                        {fetchedData.length > 0 ? (
                          <>
                            <div className="text-sm text-gray-900">
                              {fetchedData.length} orders found{" "}
                            </div>

                            <table className="min-w-full table-fixed divide-y divide-gray-300">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="w-1/7 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Status
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Order Number
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Customer
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Ship To
                                  </th>

                                  <th
                                    scope="col"
                                    className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Destination
                                  </th>

                                  <th
                                    scope="col"
                                    className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  ></th>

                                  <th
                                    scope="col"
                                    className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Tracking Number
                                  </th>

                                  <th
                                    scope="col"
                                    className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  ></th>
                                </tr>
                              </thead>

                              <tbody className="divide-y divide-gray-200 bg-white">
                                {fetchedData.map((order) => (
                                  <tr className="bg-gray-50 mb-12">
                                    <td className="p-2">
                                      <span
                                        style={{
                                          backgroundColor: order.colour,
                                          padding: 5,
                                          color: order.textColour,
                                          borderRadius: 5,
                                        }}
                                        className={`inline-flex items-center rounded-md bg-${order.colour}-50 px-7 py-1 text-xs font-medium text-${order.colour}-500 ring-1 ring-inset ring-${order.colour}-600/20`}
                                      >
                                        {order.statusName}
                                      </span>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-indigo-900 underline hover:cursor-pointer">
                                      <Link to={`/order/${order.orderID}`}>
                                        {order.orderID}
                                      </Link>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {dateFormatter(order.orderDate)}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {order.customerName}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {order.orderName}
                                    </td>

                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {order.orderCity}
                                    </td>
                                    <td>
                                      {order.orderCountry == "Canada" ? (
                                        <img
                                          width="20px"
                                          alt="Canada"
                                          src="http://purecatamphetamine.github.io/country-flag-icons/3x2/CA.svg"
                                        />
                                      ) : order.orderCountry ==
                                        "United States" ? (
                                        <img
                                          width="20px"
                                          alt="United States"
                                          src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
                                        />
                                      ) : order.orderCountry == "Mexico" ? (
                                        <img
                                          width="20px"
                                          alt="Mexico"
                                          src="http://purecatamphetamine.github.io/country-flag-icons/3x2/MX.svg"
                                        />
                                      ) : null}
                                    </td>
                                    <td className="whitespace-wrap px-3 py-4 text-sm text-gray-500">
                                      {order.tracking
                                        ? order.tracking
                                        : "No Tracking"}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </>
                        ) : searched ? (
                          <div className="font-medium text-gray-500 rounded-md mr-40 border border-gray-200 bg-gray-50 text-gray-400 text-sm inline-block min-w-full py-2 flex justify-center sm:px-6 lg:px-8">
                            No orders found
                          </div>
                        ) : (
                          <div className="text-gray-400 text-sm pb-12 inline-block min-w-full py-2 flex justify-center sm:px-6 lg:px-8"></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* content */}
          </div>
        </div>
      </>
    );
  }
}

export default OrderSearch;
