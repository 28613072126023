import { React } from "react";
import Navbar from "../Components/Navbar";
import Topbar from "../Components/Topbar";
import { useState } from "react";
import CustomersBreadcrumb from "../Components/CustomersBreadcrumb";
import CustomerName from "../Components/CustomerName";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { sendGetRequest, sendPostRequest, useGetRequest } from "../Client";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte

function CustomerProcessing() {
  const { customerID } = useParams();

    // confirmation notifications
    const notyf = new Notyf({
      position: {
        x: "right",
        y: "top",
      },
    });
  
    const successNotyf = () => {
      notyf.success("Charges updated");
    };

  const queryClient = useQueryClient();

  const { data: processing, isLoading } = useQuery({
    queryFn: () => sendGetRequest("customer/" + customerID, {}),
    queryKey: ["processing"],
  });

  const [charge1, setCharge1] = useState('');
  const [charge2, setCharge2] = useState('');
  const [charge3, setCharge3] = useState('');
  const [charge4, setCharge4] = useState('');
  const [charge5, setCharge5] = useState('');
  const [charge6, setCharge6] = useState('');
  const [charge7, setCharge7] = useState('');
  const [charge8, setCharge8] = useState('');
  const [charge9, setCharge9] = useState('');
  const [charge10, setCharge10] = useState('');

  const updateProcessing = async (path, body) => {
    const res = await sendPostRequest(path, body);
    // updatedNotyf();
  };

  const { mutateAsync: doUpdateMutation } = useMutation({
    mutationFn: (data) => {
      return updateProcessing(data.path, { customerID, ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["processing"] });
    },
  });

  const handleSubmit = async (e) => {
    // e.preventDefault();

    try {
      const res = await doUpdateMutation({
        path: "customer/processing/update",
        body: {
          charge1: charge1 ? charge1 : processing?.processingCharges[0]?.amount,
          charge2: charge2 ? charge2 : processing?.processingCharges[1]?.amount,
          charge3: charge3 ? charge3 : processing?.processingCharges[2]?.amount,
          charge4: charge4 ? charge4 : processing?.processingCharges[3]?.amount,
          charge5: charge5 ? charge5 : processing?.processingCharges[4]?.amount,
          charge6: charge6 ? charge6 : processing?.processingCharges[5]?.amount,
          charge7: charge7 ? charge7 : processing?.processingCharges[6]?.amount,
          charge8: charge8 ? charge8 : processing?.processingCharges[7]?.amount,
          charge9: charge9 ? charge9 : processing?.processingCharges[8]?.amount,
          charge10: charge10 ? charge10 : processing?.processingCharges[9]?.amount,
          customerID: customerID,
        },
      });
      successNotyf();
    } catch (e) {
      // errorNotyf();
    }
  };


  if (!isLoading) {
    return (
      <>
        <div className="flex flex-row w-full">
          <Navbar />

          <div className="flex flex-col w-4/5 bg-gray-100">
            <Topbar />

            <CustomersBreadcrumb />

            <CustomerName customerName={processing?.customer?.customerName} />

            {/* content */}
            <div className="bg-white px-4 sm:px-6 lg:px-8 border border-gray-200 ml-10 mr-10 mt-10 mb-10">
              <h1 className="text-2xl font-semibold leading-6 text-gray-900 mb-5 mt-10">
                Processing
              </h1>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="relative">
                      <table className="min-w-full table-fixed divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="w-1/7 py-3.5 pr-3 text-left text-sm font-medium text-gray-900"
                            >
                              Order Processing Charge Type
                            </th>
                            <th
                              scope="col"
                              className="w-1/7 px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                            >
                              Charge ($)
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              0-10lbs / First 3 line items
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="charge1"
                                // value={"4.35"}
                                onChange={(e) => setCharge1(e.target.value)}
                                value={charge1 ? charge1 : processing?.processingCharges[0]?.amount}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              11-20lbs / First 3 line items
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="charge2"
                                // value="4.95"
                                onChange={(e) => setCharge2(e.target.value)}
                                value={charge2 ? charge2 : processing?.processingCharges[1]?.amount}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Per lb over 20lbs
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="charge3"
                                // value="0.11"
                                onChange={(e) => setCharge3(e.target.value)}
                                value={charge3 ? charge3 : processing?.processingCharges[2]?.amount}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Per line item over 3
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="charge4"
                                // value="0.72"
                                onChange={(e) => setCharge4(e.target.value)}
                                value={charge4 ? charge4 : processing?.processingCharges[3]?.amount}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Per package over 1
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="charge5"
                                // value="0.68"
                                onChange={(e) => setCharge5(e.target.value)}
                                value={charge5 ? charge5 : processing?.processingCharges[4]?.amount}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Per piece
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="charge6"
                                // value="0.03"
                                onChange={(e) => setCharge6(e.target.value)}
                                value={charge6 ? charge6 : processing?.processingCharges[5]?.amount}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Per Carton
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="charge7"
                                // value="1.95"
                                onChange={(e) => setCharge7(e.target.value)}
                                value={charge7 ? charge7 : processing?.processingCharges[6]?.amount}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                         
                        </tbody>
                      </table>

                      <table className="min-w-full table-fixed divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="w-1/7 py-3.5 pr-3 text-left text-sm font-medium text-gray-900"
                            >
                              Order Receiving Charge Type
                            </th>
                            <th
                              scope="col"
                              className="w-1/7 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            ></th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">

                        <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              SKID charge
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="charge8"
                                // value="5.00"
                                onChange={(e) => setCharge8(e.target.value)}
                                value={charge8 ? charge8 : processing?.processingCharges[7]?.amount}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Per item up to 100lbs
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="charge9"
                                // value="12.10"
                                onChange={(e) => setCharge9(e.target.value)}
                                value={charge9 ? charge9 : processing?.processingCharges[8]?.amount}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Each additional pound
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="charge10"
                                // value="0.08"
                                onChange={(e) => setCharge10(e.target.value)}
                                value={charge10 ? charge10 : processing?.processingCharges[9]?.amount}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="flex items-center justify-end gap-x-6 mb-5">
                        <button
                          type="submit"
                          onClick={() => handleSubmit()}
                          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* content */}
          </div>
        </div>
      </>
    );
  }
}

export default CustomerProcessing;
